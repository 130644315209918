import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VContainer,[_c('div',{attrs:{"id":"divStep4"}},[_c(VForm,{ref:"formStep"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticStyle:{"float":"right"}},[_c(VSwitch,{attrs:{"label":("Chci vystavit na portál: " + (_vm.formStep.teamio_feed_active ? 'Áno' : 'Ne'))},model:{value:(_vm.formStep.teamio_feed_active),callback:function ($$v) {_vm.$set(_vm.formStep, "teamio_feed_active", $$v)},expression:"formStep.teamio_feed_active"}})],1)])],1)],1)],1)]),_c('div',{staticStyle:{"text-align":"right"}},[_c('small',{staticStyle:{"color":"red"}},[_vm._v("položky označené * je potřeba vyplnit")])])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#FF6160","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" ZRUŠIT ")]),_c(VBtn,{attrs:{"color":"#6D8793","text":""},on:{"click":_vm.handlePreviousStep}},[_vm._v(" ZPĚT ")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.handleNextStep}},[_vm._v(" "+_vm._s(_vm.last_step === true ? 'ULOŽIT' : 'POKRAČOVAT')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }