<template>
  <v-card>
    <v-card-text>
      <v-container>
        <div id="divStep4">
          <v-form ref="formStep">
            <v-row>
              <!-- VYSTAVIT NA PORTAL -->
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <div
                style="float:right">
                  <v-switch
                    v-model="formStep.teamio_feed_active"
                    :label="`Chci vystavit na portál: ${formStep.teamio_feed_active ? 'Áno' : 'Ne'}`"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
      <div style="text-align:right;">
        <small style="color:red">položky označené * je potřeba vyplnit</small>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="#FF6160"
        text
        @click="$emit('close')"
      >
        ZRUŠIT
      </v-btn>
      <v-btn
        color="#6D8793"
        text
        @click="handlePreviousStep"
      >
        ZPĚT
      </v-btn>
      <v-btn
        color="success"
        text
        @click="handleNextStep"
      >
        {{ last_step === true ? 'ULOŽIT' : 'POKRAČOVAT' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['typ_inzeratu', 'last_step', 'jobForm'],
  data() {
    return {
      formStep: {},
    }
  },
  created() {
    console.log('[form-step4.vue] *** created')
  },
  methods: {
    handleNextStep() {
      if (this.formStep.jenprace_food_active !== true) { // v pripade, ze sa na portal nevystavuje, nevaliduj pri kliknuti na pokracovat
        this.$emit('nextStep', { validate: true, dataForm: this.formStep })
      }

      // ---

      if (this.$refs.formStep.validate()) { // zatial vypinam validate
        this.$emit('nextStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep4').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
    handlePreviousStep() {
      if (this.$refs.formStep.validate()) {
        this.$emit('previousStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep4').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
  },
}
</script>
