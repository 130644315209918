import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VContainer,[_c('div',{attrs:{"id":"divStep2"}},[_c(VForm,{ref:"formStep"},_vm._l((_vm.formStep.nazov_pozicie_dalsie),function(nazov,index){return _c(VRow,{key:nazov + index},[_c(VCol,{attrs:{"cols":"10","sm":"10","md":"10"}},[_c(VTextField,{attrs:{"value":nazov,"rules":_vm.ruleNazovPozicie,"label":"Název pozice *","required":"","clearable":""},on:{"blur":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleNazovPozicie.apply(void 0, argsArray.concat( [index] ))}}})],1),_c(VCol,{attrs:{"cols":"2","sm":"2","md":"2"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index===_vm.formStep.nazov_pozicie_dalsie.length-1),expression:"index===formStep.nazov_pozicie_dalsie.length-1"}],staticStyle:{"line-height":"70px"}},[(index < _vm.maxNazvov-1)?_c(VBtn,{staticStyle:{"margin-right":"10px"},attrs:{"outlined":"","color":"primary","tile":"","small":""},on:{"click":function($event){return _vm.formStep.nazov_pozicie_dalsie.push('')}}},[_vm._v(" PŘIDAT ")]):_vm._e(),(index > 0)?_c(VBtn,{attrs:{"outlined":"","color":"#FF6160","icon":"","tile":"","small":""},on:{"click":function($event){return _vm.formStep.nazov_pozicie_dalsie.splice(index, 1)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1):_vm._e()],1)])],1)}),1)],1)]),_c('div',{staticStyle:{"text-align":"right"}},[_c('small',{staticStyle:{"color":"red"}},[_vm._v("položky označené * je potřeba vyplnit")])])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#FF6160","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" ZRUŠIT ")]),_c(VBtn,{attrs:{"color":"#6D8793","text":""},on:{"click":_vm.handlePreviousStep}},[_vm._v(" ZPĚT ")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.handleNextStep}},[_vm._v(" POKRAČOVAT ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }