import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.records,"options":_vm.options,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"server-items-length":_vm.totalRecords,"loading":_vm.loading,"loading-text":"Hledám inzeráty, prosím o strpění...","locale":"cs-CS","items_per_page":"15","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.itemsPerPage,"update:page":_vm.changePage,"update:sort-by":_vm.sortBy,"update:sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VRow,{staticStyle:{"padding":"20px"}},[_c(VCol,[(item.nazov_pozicie)?_c(VRow,[_c(VCol,{attrs:{"cols":12}},[_c('b',[_vm._v("Hlavní název inzerátu")]),_c(VRow,{staticStyle:{"padding-left":"15px"}},[_c(VCol,{attrs:{"cols":5}},[_vm._v(_vm._s(item.nazov_pozicie))]),_vm._l((item.ids),function(ids){return _c('span',{key:ids.id},[(item.nazov_pozicie===ids.nazov_pozicie)?_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('b',[_vm._v("#"+_vm._s(ids.id_text))])])]}}],null,true)},[_c('span',[_vm._v("Hashtag")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticStyle:{"margin-left":"10px","margin-right":"10px"}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(ids.datecreated,'DD.MM.YYYY HH:mm:ss.SSS'))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Datum prvního vygenerování hashtagu")])])],1):_vm._e()])})],2)],1)],1):_vm._e(),(item.nazev_pozice_jenprace)?_c(VRow,[_c(VCol,[_c('u',[_vm._v("název inzerátu pro "),_c('b',[_vm._v("jenprace.cz")])]),_c(VRow,{staticStyle:{"padding-left":"15px"}},[_c(VCol,{attrs:{"cols":5}},[_vm._v(_vm._s(item.nazev_pozice_jenprace))]),_vm._l((item.ids),function(ids){return _c('span',{key:ids.id},[(item.nazev_pozice_jenprace===ids.nazov_pozicie)?_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('b',[_vm._v("#"+_vm._s(ids.id_text))])])]}}],null,true)},[_c('span',[_vm._v("Hashtag")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticStyle:{"margin-left":"10px","margin-right":"10px"}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(ids.datecreated,'DD.MM.YYYY HH:mm:ss.SSS'))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Datum prvního vygenerování hashtagu")])])],1):_vm._e()])})],2)],1)],1):_vm._e(),(item.teamio_service && item.teamio_service.length > 0)?_c(VRow,[_c(VCol,[_c('u',[_vm._v("název inzerátu pro "),_c('b',[_vm._v("teamio.cz")])]),_vm._l((item.teamio_service),function(teamio){return _c(VRow,{key:teamio._id,staticStyle:{"padding-left":"15px"}},[_c(VCol,{attrs:{"cols":5}},[_c('div',[_vm._v(_vm._s(teamio.nazov_pozicie))]),_c('div',_vm._l((teamio.portal),function(portal,index){return _c(VChip,{key:index,staticStyle:{"margin-right":"5px"},attrs:{"x-small":"","outlined":"","color":"primary"}},[_c('b',[_vm._v(_vm._s(portal.replace('cz', '.cz')))])])}),1)]),_vm._l((item.ids),function(ids){return _c('span',{key:ids.id,attrs:{"cols":7}},[(teamio.nazov_pozicie===ids.nazov_pozicie)?_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('b',[_vm._v("#"+_vm._s(ids.id_text))])])]}}],null,true)},[_c('span',[_vm._v("Hashtag")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticStyle:{"margin-left":"10px","margin-right":"10px"}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(ids.datecreated,'DD.MM.YYYY HH:mm:ss.SSS'))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Datum prvního vygenerování hashtagu")])])],1):_vm._e()])})],2)})],2)],1):_vm._e(),(item.nazev_pozice_fajnbrigady)?_c(VRow,[_c(VCol,[_c('u',[_vm._v("název inzerátu pro "),_c('b',[_vm._v("fajn-sprava.cz")])]),_c(VRow,{staticStyle:{"padding-left":"15px"}},[_c(VCol,{attrs:{"cols":5}},[_vm._v(_vm._s(item.nazev_pozice_fajnbrigady))]),_vm._l((item.ids),function(ids){return _c('span',{key:ids.id},[(item.nazev_pozice_fajnbrigady===ids.nazov_pozicie)?_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('b',[_vm._v("#"+_vm._s(ids.id_text))])])]}}],null,true)},[_c('span',[_vm._v("Hashtag")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticStyle:{"margin-left":"10px","margin-right":"10px"}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(ids.datecreated,'DD.MM.YYYY HH:mm:ss.SSS'))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Datum prvního vygenerování hashtagu")])])],1):_vm._e()])})],2)],1)],1):_vm._e()],1)],1)],1)]}},{key:"item.btn_actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"32px","min-height":"32px","display":"block"},on:{"mouseover":function($event){_vm.small_show_btn=item.id},"mouseleave":function($event){_vm.small_show_btn=-1}}},[(_vm.small_show_btn===item.id)?_c(VSpeedDial,{attrs:{"open-on-hover":true,"transition":_vm.transition,"direction":"right"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","fab":"","x-small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")]):_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCircleEditOutline)+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.btn_action),callback:function ($$v) {_vm.btn_action=$$v},expression:"btn_action"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"color":"whitesmoke"},attrs:{"fab":"","x-small":"","color":"#4BAF4F","disabled":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1)]}}],null,true)},[_vm._v(" Upravit inzerát ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"color":"whitesmoke"},attrs:{"fab":"","x-small":"","color":"#F66160","disabled":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiArchiveArrowUpOutline))])],1)]}}],null,true)},[_vm._v(" Archivovat inzerát ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"color":"whitesmoke"},attrs:{"fab":"","x-small":"","color":"#1B66C0","disabled":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiContentDuplicate))])],1)]}}],null,true)},[_vm._v(" Vytvořit duplicitní inzerát ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"color":"whitesmoke"},attrs:{"fab":"","x-small":"","color":"#B7B7B7","disabled":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiIdentifier))])],1)]}}],null,true)},[_vm._v(" Vložit ID inzerátu do schránky ")])],1):_c(VIcon,{staticStyle:{"margin-top":"3px"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCircleEditOutline)+" ")])],1)]}},{key:"item.typ_inzeratu",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticStyle:{"margin-right":"5px"},attrs:{"color":("" + (item.typ_inzeratu === 'TPP' ? '#F54C3E' : '#ededed')),"outlined":"","label":"","x-small":""}},[_vm._v(" TPP ")]),_c(VChip,{attrs:{"color":("" + (item.typ_inzeratu !== 'TPP' ? '#FF9D1A' : '#ededed')),"outlined":"","label":"","x-small":""}},[_vm._v(" BRI ")]),_c(VChip,{attrs:{"color":("" + (item.postup_schvalenia.schvaleno ? '#54B256' : '#ededed')),"outlined":"","label":"","x-small":""}},[_vm._v("  SCHVÁLENO  ")])]}},{key:"item.konzultant.idIdentita",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"size":"32","color":_vm.getColor(item.konzultant.idIdentita.firstname[0])}},'v-avatar',attrs,false),on),[_c('span',{staticStyle:{"color":"whitesmoke"}},[_vm._v(_vm._s(item.konzultant.idIdentita.firstname[0])+_vm._s(item.konzultant.idIdentita.surname[0]))])])]}}],null,true)},[_vm._v(" "+_vm._s(item.konzultant.idIdentita.firstname)+" "+_vm._s(item.konzultant.idIdentita.surname)+" ")])]}}])}),_c(VBtn,{attrs:{"disabled":"","color":"primary","fab":"","top":"","right":"","absolute":""},on:{"click":function($event){return _vm.openInzeratForm(true)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1),_c('form-inzerat',{attrs:{"dialog":_vm.open_form_inzerat,"dataForm":_vm.data_form_inzerat},on:{"close":function($event){_vm.open_form_inzerat=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }