<template>
  <div>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="records"
        :options.sync="options"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
        :server-items-length="totalRecords"
        :loading="loading"
        loading-text="Hledám inzeráty, prosím o strpění..."
        locale="cs-CS"
        items_per_page="15"
        show-expand
        @update:items-per-page="itemsPerPage"
        @update:page="changePage"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"
        class="elevation-1"
      >
        <!-- ROZSIRENNE DATA PRE VNORENU TABULKU  -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row style="padding:20px;">
              <v-col>
                <!-- hlavny nazov -->
                <v-row v-if="item.nazov_pozicie">
                  <v-col :cols="12">
                    <b>Hlavní název inzerátu</b>
                    <v-row style="padding-left:15px">
                      <v-col :cols="5">{{ item.nazov_pozicie }}</v-col>
                      <span v-for="ids in item.ids" :key="ids.id">
                        <span v-if="item.nazov_pozicie===ids.nazov_pozicie">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  outlined
                                  v-bind="attrs"
                                  v-on="on">
                                  <b>#{{ ids.id_text }}</b>
                                </v-chip>
                            </template>
                            <span>Hashtag</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                style="margin-left:10px;margin-right:10px;"
                                v-bind="attrs"
                                v-on="on">
                                <v-icon
                                  small
                                >
                                  {{ icons.mdiUpdate }}
                                </v-icon>
                                <span
                                  style="margin-left:10px;"
                                >
                                  {{ ids.datecreated | moment('DD.MM.YYYY HH:mm:ss.SSS')}}
                                </span>
                              </v-chip>
                            </template>
                            <span>Datum prvního vygenerování hashtagu</span>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- jenprace.cz -->
                <v-row v-if="item.nazev_pozice_jenprace">
                  <v-col>
                    <u>název inzerátu pro <b>jenprace.cz</b></u>
                    <v-row style="padding-left:15px">
                      <v-col :cols="5">{{ item.nazev_pozice_jenprace }}</v-col>
                        <span v-for="ids in item.ids" :key="ids.id">
                          <span v-if="item.nazev_pozice_jenprace===ids.nazov_pozicie">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    outlined
                                    v-bind="attrs"
                                    v-on="on">
                                    <b>#{{ ids.id_text }}</b>
                                  </v-chip>
                              </template>
                              <span>Hashtag</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  style="margin-left:10px;margin-right:10px;"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon
                                    small
                                  >
                                    {{ icons.mdiUpdate }}
                                  </v-icon>
                                  <span
                                    style="margin-left:10px;"
                                  >
                                    {{ ids.datecreated | moment('DD.MM.YYYY HH:mm:ss.SSS')}}
                                  </span>
                                </v-chip>
                              </template>
                              <span>Datum prvního vygenerování hashtagu</span>
                            </v-tooltip>
                          </span>
                        </span>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- teamio.cz -->
                <v-row v-if="item.teamio_service && item.teamio_service.length > 0">
                  <v-col>
                    <u>název inzerátu pro <b>teamio.cz</b></u>
                    <v-row style="padding-left:15px" v-for="teamio in item.teamio_service" :key="teamio._id">
                      <v-col :cols="5">
                        <div>{{ teamio.nazov_pozicie }}</div>
                        <div>
                          <v-chip
                            v-for="(portal, index) in teamio.portal" :key="index"
                            style="margin-right:5px"
                            x-small
                            outlined
                            color="primary"
                          >
                            <b>{{ portal.replace('cz', '.cz') }}</b>
                          </v-chip>
                        </div>
                      </v-col>
                      <span :cols="7" v-for="ids in item.ids" :key="ids.id">
                        <span v-if="teamio.nazov_pozicie===ids.nazov_pozicie">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                outlined
                                v-bind="attrs"
                                v-on="on">
                                <b>#{{ ids.id_text }}</b>
                              </v-chip>
                            </template>
                            <span>Hashtag</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                style="margin-left:10px;margin-right:10px"
                                v-bind="attrs"
                                v-on="on">
                                <v-icon
                                  small
                                >
                                  {{ icons.mdiUpdate }}
                                </v-icon>
                                <span
                                  style="margin-left:10px;"
                                >
                                  {{ ids.datecreated | moment('DD.MM.YYYY HH:mm:ss.SSS')}}
                                </span>
                              </v-chip>
                            </template>
                            <span>Datum prvního vygenerování hashtagu</span>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- fajn-sprava.cz -->
                <v-row v-if="item.nazev_pozice_fajnbrigady">
                  <v-col>
                    <u>název inzerátu pro <b>fajn-sprava.cz</b></u>
                    <v-row style="padding-left:15px">
                      <v-col :cols="5">{{ item.nazev_pozice_fajnbrigady }}</v-col>
                      <span v-for="ids in item.ids" :key="ids.id">
                        <span v-if="item.nazev_pozice_fajnbrigady===ids.nazov_pozicie">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                outlined
                                v-bind="attrs"
                                v-on="on">
                                <b>#{{ ids.id_text }}</b>
                              </v-chip>
                            </template>
                            <span>Hashtag</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                style="margin-left:10px;margin-right:10px"
                                v-bind="attrs"
                                v-on="on">
                                <v-icon
                                  small
                                >
                                  {{ icons.mdiUpdate }}
                                </v-icon>
                                <span
                                  style="margin-left:10px;"
                                >
                                  {{ ids.datecreated | moment('DD.MM.YYYY HH:mm:ss.SSS')}}
                                </span>
                              </v-chip>
                            </template>
                            <span>Datum prvního vygenerování hashtagu</span>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </td>
        </template>
        <!-- Stlpec: BTN ACTIONS -->
        <template v-slot:item.btn_actions="{ item }">
          <div
            style="min-width:32px;min-height:32px;display:block"
            @mouseover="small_show_btn=item.id"
            @mouseleave="small_show_btn=-1"
          >
            <v-speed-dial
              v-if="small_show_btn===item.id"
              v-model="btn_action"
              :open-on-hover="true"
              :transition="transition"
              direction="right"
            >
              <template v-slot:activator>
                <v-btn
                  v-model="fab"
                  color="primary"
                  fab
                  x-small
                >
                  <v-icon v-if="fab">
                    {{ icons.mdiClose }}
                  </v-icon>
                  <v-icon v-else>
                    {{ icons.mdiCircleEditOutline }}
                  </v-icon>
                </v-btn>
              </template>
               <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      x-small
                      color="#4BAF4F"
                      style="color:whitesmoke;"
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    >
                      <v-icon>{{ icons.mdiPencil }}</v-icon>
                    </v-btn>
                  </template>
                  Upravit inzerát
               </v-tooltip>
               <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      x-small
                      color="#F66160"
                      style="color:whitesmoke;"
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    >
                      <v-icon>{{ icons.mdiArchiveArrowUpOutline }}</v-icon>
                    </v-btn>
                  </template>
                  Archivovat inzerát
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    color="#1B66C0"
                    style="color:whitesmoke;"
                    v-bind="attrs"
                    v-on="on"
                    disabled
                  >
                    <v-icon>{{ icons.mdiContentDuplicate }}</v-icon>
                  </v-btn>
                  </template>
                  Vytvořit duplicitní inzerát
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    color="#B7B7B7"
                    style="color:whitesmoke;"
                    v-bind="attrs"
                    v-on="on"
                    disabled
                  >
                    <v-icon>{{ icons.mdiIdentifier }}</v-icon>
                  </v-btn>
                  </template>
                  Vložit ID inzerátu do schránky
              </v-tooltip>
            </v-speed-dial>
            <v-icon
              v-else
              style="margin-top:3px"
            >
              {{ icons.mdiCircleEditOutline }}
            </v-icon>
          </div>
        </template>
        <!-- Stlpec: TYP INZERATU -->
        <template v-slot:item.typ_inzeratu="{ item }">
            <!-- TYP INZERATU -->
            <v-chip
              style="margin-right:5px"
              :color="`${item.typ_inzeratu === 'TPP' ? '#F54C3E' : '#ededed'}`"
              outlined
              label
              x-small
            >
              TPP
            </v-chip>
            <v-chip
              :color="`${item.typ_inzeratu !== 'TPP' ? '#FF9D1A' : '#ededed'}`"
              outlined
              label
              x-small
            >
              BRI
            </v-chip>
            <!-- SCHVALENO -->
            <v-chip
              :color="`${item.postup_schvalenia.schvaleno ? '#54B256' : '#ededed'}`"
              outlined
              label
              x-small
            >
              &nbsp;SCHVÁLENO&nbsp;
            </v-chip>
        </template>
        <!-- Stlpec: KONZULTANT -->
        <template v-slot:item.konzultant.idIdentita="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="32"
                :color="getColor(item.konzultant.idIdentita.firstname[0])"
                v-bind="attrs"
                v-on="on"
              >
                <span style="color:whitesmoke">{{ item.konzultant.idIdentita.firstname[0] }}{{ item.konzultant.idIdentita.surname[0] }}</span>
              </v-avatar>
            </template>
            {{ item.konzultant.idIdentita.firstname }} {{ item.konzultant.idIdentita.surname }}
          </v-tooltip>
        </template>
      </v-data-table>
      <v-btn
        disabled
        color="primary"
        fab
        top
        right
        absolute
        @click="openInzeratForm(true)"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </v-card>

    <form-inzerat
      :dialog="open_form_inzerat"
      :dataForm="data_form_inzerat"
      @close="open_form_inzerat=false">
    </form-inzerat>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */

import axios from 'axios'
import { mdiDotsVertical, mdiClipboardTextClockOutline, mdiClipboardTextClock, mdiUpdate, mdiPlus, mdiDelete, mdiAccountCircle, mdiPencil, mdiClose, mdiApplicationEditOutline, mdiCircleEditOutline, mdiArchiveArrowUpOutline, mdiContentDuplicate, mdiIdentifier } from '@mdi/js'
import FormInzerat from './components/form-inzerat.vue'

export default {
  components: {
    'form-inzerat': FormInzerat,
  },
  data() {
    return {
      btn_action: false,
      small_show_btn: -1,
      fab: false,
      transition: 'slide-x-reverse-transition',
      open_form_inzerat: false,
      data_form_inzerat: {},
      icons: {
        mdiClipboardTextClock,
        mdiUpdate,
        mdiPlus,
        mdiDelete,
        mdiAccountCircle,
        mdiPencil,
        mdiClose,
        mdiApplicationEditOutline,
        mdiCircleEditOutline,
        mdiArchiveArrowUpOutline,
        mdiContentDuplicate,
        mdiIdentifier,
      },
      mdiPlus,
      mdiDotsVertical,
      mdiClipboardTextClockOutline,
      mdiClipboardTextClock,
      mdiApplicationEditOutline,
      mdiCircleEditOutline,
      mdiArchiveArrowUpOutline,
      mdiContentDuplicate,
      mdiIdentifier,
      totalRecords: 0,
      records: [],
      loading: false,
      options: {},
      sort: {
        field: 'datum_vystaveni',
        desc: false,
      },

      // Vertical - align: start, center, end, baseline, stretch
      // Horizontal - justify: start, cener, end, space-around, space-between

      headers: [
        {
          text: 'Typ',
          align: 'center',
          value: 'typ_inzeratu',
          width: '125px',
        },
        {
          text: '',
          align: 'end',
          value: 'konzultant.idIdentita',
          sortable: false,
          width: '64px',
        },
        {
          text: '',
          sortable: false,
          value: 'btn_actions',
        },
        {
          text: 'Název pozice',
          sortable: true,
          value: 'nazov_pozicie',
        },
        {
          text: 'Od',
          align: 'end',
          value: 'datum_vystaveni',
          width: '59px',
        },
        {
          text: 'Do',
          align: 'start',
          value: 'datum_stazeni',
          width: '59px',
        },
        {
          text: 'Pobočka',
          align: 'start',
          value: 'pobocka.mesto_pobocky',
          sortable: false,
        },
        {
          text: 'Klient',
          align: 'end',
          value: 'jmeno_klienta',
        },
        {
          text: 'Lokalita',
          value: 'lokalita.label',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
      fetch_settings: {
        filter: {
          stav_inzeratu: 'aktivni',
        },
        columns: {
          typ_inzeratu: 1,
          nazov_pozicie: 1,
          nazev_pozice_jenprace: 1,
          nazev_pozice_fajnbrigady: 1,
          datum_vystaveni: 1,
          datum_stazeni: 1,
          'konzultant.idIdentita': 1,
          pobocka: 1,
          jmeno_klienta: 1,
          lokalita: 1,
          postup_schvalenia: 1,
          teamio_service: 1,
          usercreated: 1,
        },
        paging: {
          limit: 15,
          skip: 0,
        },
        sort: {
          datum_vystaveni: false,
        },
      },
    }
  },
  watch: {
    options: {
      handler() {
        // this.fetchJob()
      },
      deep: true,
    },
    '$store.state.inzerce': {
      handler(newVal) {
        let filter = JSON.stringify(this.fetch_settings.filter)

        console.log('state.inzerce', newVal)

        // FILTER - schvaleno
        if (newVal.filter_schvaleno === 'off') {
          delete this.fetch_settings.filter['postup_schvalenia.schvaleno']
        } else if (newVal.filter_schvaleno === 'schvaleno') {
          this.fetch_settings.filter['postup_schvalenia.schvaleno'] = 'true'
        } else if (newVal.filter_schvaleno === 'neschvaleno') {
          this.fetch_settings.filter['postup_schvalenia.schvaleno'] = 'false'
        }

        // FILTER - TPP a BRIGÁDA
        if (newVal.filter_typ_inzeratu === 'off') {
          delete this.fetch_settings.filter.typ_inzeratu
        } else if (newVal.filter_typ_inzeratu === 'TPP') {
          this.fetch_settings.filter.typ_inzeratu = 'TPP'
        } else if (newVal.filter_typ_inzeratu === 'BRIGÁDA') {
          this.fetch_settings.filter.typ_inzeratu = 'BRIGÁDA'
        }

        // FILTER - search input
        if (newVal.filter_search === null || newVal.filter_search === '') {
          delete this.fetch_settings.filter.$or
          delete this.fetch_settings.filter.hashtag
        } else {

          // ak je prvy znak #, bude sa inzerat hladat podla hastagu

          if (newVal.filter_search[0] === '#') {
            this.fetch_settings.filter.hashtag = newVal.filter_search.replace('#', '')
          } else {
            this.fetch_settings.filter.$or = [
              { nazov_pozicie: { $regex: newVal.filter_search, $options: 'i' } },
              { jmeno_klienta: { $regex: newVal.filter_search, $options: 'i' } },
              { cislo_objednavky: { $regex: newVal.filter_search, $options: 'i' } },
              { ic_klienta: { $regex: newVal.filter_search, $options: 'i' } },
            ]
          }
        }

        // FILTER - konzultant
        if (newVal.filter_konzultant_search.length === 0) {
          delete this.fetch_settings.filter['konzultant.idIdentita']
        } else {
          this.fetch_settings.filter['konzultant.idIdentita'] = { $in: newVal.filter_konzultant_search }
        }

        // FILTER - clear
        if (newVal.filter_clear === true) {
          this.fetch_settings.filter = {
            stav_inzeratu: 'aktivni',
          }
          this.$store.state.inzerce.filter_clear = false
        }

        let filter_current = JSON.stringify(this.fetch_settings.filter)

        // FILTER - ak je nastaveny filter, poznac to
        this.$store.state.inzerce.filter_is_activate = !(filter_current === JSON.stringify({ stav_inzeratu: 'aktivni' }))

        // FILTRUJ => VYTVOR REQUEST NA SERVER
        if (filter !== filter_current) { // volaj novy request iba ak sa zmenil filter
          this.fetchJob()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    console.log('[index.vue] inzerce created ***')
    this.fetchJob()
  },
  methods: {
    getColor(p_char) {
      let color = ''
      switch (p_char.toLowerCase()) {
        case 'l':
          color = '#4BAF4F'
          break
        case 'k':
          color = '#FF6160'
          break
        case 'a':
          color = '#FB8D00'
          break
        case 'd':
          color = '#1A66C0'
          break
        default:
          color = '#b7b7b7'
      }

      return color
    },
    fetchJob() {

      // kontrola aby sa nerobilo viac requestov v jednom momente

      if (this.loading === true) {
        return
      }

      // ---

      this.loading = true
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/inzerce/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.records = resp.data.db_data
          for (let index = 0; index < this.records.length; index++) {
            const element = this.records[index]
            this.records[index].datum_vystaveni = this.$moment(element.datum_vystaveni).isSame(new Date(), 'year') ? this.$moment(element.datum_vystaveni).format('DD.MM HH:mm') : this.$moment(element.datum_vystaveni).format('DD.MM.YYYY HH:mm')
            this.records[index].datum_stazeni = this.$moment(element.datum_stazeni).isSame(new Date(), 'year') ? this.$moment(element.datum_stazeni).format('DD.MM HH:mm') : this.$moment(element.datum_stazeni).format('DD.MM.YYYY HH:mm')

            // if (element.konzultant && element.konzultant.idIdentita) {
            //   this.records[index].konzultant.idIdentita.fullname = `${element.konzultant.idIdentita.firstname} ${element.konzultant.idIdentita.surname}`
            // }

          }
          this.totalRecords = resp.data.total
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    scrollTo(p_top) {
      p_top = p_top || 0
      window.scrollTo({
        top: p_top,
        left: 0,
        behavior: 'smooth',
      })
    },
    itemsPerPage(p_limit) {
      // console.log('itemsPerPage, p_limit => ', p_limit)
      this.fetch_settings.paging.limit = p_limit
      this.fetchJob()
      this.scrollTo(20)
    },
    changePage(p_page) {
      // console.log('changePage, p_page => ', p_page)
      let from_record = (p_page * this.fetch_settings.paging.limit) - this.fetch_settings.paging.limit
      this.fetch_settings.paging.page = p_page
      this.fetch_settings.paging.skip = from_record
      this.fetchJob()
    },
    sortBy(p_sort) {
      // console.log('changePage, p_sort => ', p_sort)
      this.sort.field = p_sort[0]
    },
    sortDesc(p_desc) {
      // console.log('changePage, p_desc => ', p_desc)
      if (p_desc.length > 0) {
        this.sort.desc = p_desc[0] === true ? 1 : -1
      } else {
        this.sort.desc = undefined
      }
      this.buildSort()
    },
    buildSort() {
      // console.log('buildSort, p_desc => ', this.sort)
      this.fetch_settings.sort = {}
      if ((this.sort.field) && (this.sort.desc)) {
        this.fetch_settings.sort[this.sort.field] = this.sort.desc
      }
      this.fetchJob()
    },
    openInzeratForm(p_action) {
      this.data_form_inzerat = {
        is_new: p_action || false, // true|false <= create|update inzeratu
      }
      this.open_form_inzerat = true
    },
  },
}
</script>
