import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"Název pozice pro fajnsprava","required":""}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#FF6160","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" ZRUŠIT ")]),_c(VBtn,{attrs:{"color":"#6D8793","text":""},on:{"click":_vm.handlePreviousStep}},[_vm._v(" ZPĚT ")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.handleNextStep}},[_vm._v(" "+_vm._s(_vm.last_step === true ? 'ULOŽIT' : 'POKRAČOVAT')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }