<template>
  <v-card>
    <v-card-text>
      <v-container>
        <!-- FORMULAR -->
        <div id="divStep1">
          <v-form ref="formStep">
            <v-row>
              <!-- NAZOV POZICIE -->
              <v-col
                cols="12"
                sm="12"
                md="9"
              >
                <v-text-field
                  label="Název pozice *"
                  clearable
                  :rules="ruleNazovPozicie"
                  v-model="formStep.nazov_pozicie"
                  required
                  @change="handleNazovPozicieDalsie"
                ></v-text-field>
              </v-col>

              <!-- POZICE INTERNI -->
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <div
                style="float:right">
                  <v-switch
                    v-model="formStep.pozice_interni"
                    :label="`Interní pozice: ${formStep.pozice_interni ? 'Áno' : 'Ne'}`"
                  ></v-switch>
                </div>
              </v-col>

              <!-- NAPLN PRACE -->
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-textarea
                  label=" Náplň práce *"
                  :rules="ruleNaplnPrace"
                  v-model="formStep.napln_prace"
                  hint="popis pracovnej pozice"
                  persistent-hint
                  counter
                ></v-textarea>
              </v-col>

              <!-- NABIZIME -->
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-textarea
                  label="Nabízíme *"
                  :rules="ruleNabizime"
                  v-model="formStep.nabizime"
                  hint="popis co všecho může uchazeč získat"
                  persistent-hint
                  required
                  counter
                ></v-textarea>
              </v-col>

              <!-- POZADUJEME -->
              <v-col cols="12">
                <v-textarea
                  label="Požadujeme *"
                  :rules="rulePozadujeme"
                  v-model="formStep.pozadujeme"
                  hint="co všechno by uchazeč měl ovládat, vědět"
                  persistent-hint
                  required
                  counter
                ></v-textarea>
              </v-col>

              <!-- TYP UVAZKU -->
              <v-col
                v-if="typ_inzeratu === 'TPP'"
                cols="12"
                md="6"
              >
                <v-select
                  :items="optTypUvazku"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.typ_uvazku"
                  label="Typ úvazku *"
                  :rules="ruleTypUvazku"
                ></v-select>
              </v-col>

              <!-- MINIMALNI VZDELANI -->
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  :items="optMinimalniVzdelani"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.minimalni_vzdelani"
                  label="Minimální vzdelání *"
                  :rules="ruleMinimalniVzdelani"
                ></v-select>
              </v-col>

              <!-- ZAPATI -->
              <v-col
                v-if="typ_inzeratu === 'TPP'"
                cols="12"
                md="4"
              >
                <v-select
                  :items="optZapati"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.zapati"
                  label="Zápatí"
                ></v-select>
              </v-col>

              <!-- KRAJ -->
              <v-col
                cols="12"
                :md="typ_inzeratu === 'TPP' ? 4 : 6"
              >
                <v-select
                  :items="optKraj"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.kraj"
                  label="Kraj *"
                  :rules="ruleKraj"
                  multiple
                ></v-select>
              </v-col>

              <!-- LOKALITA -->
              <v-col
                cols="12"
                :md="typ_inzeratu === 'TPP' ? 4 : 12"
              >
                <v-autocomplete
                  :items="optLokalita"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.lokalita"
                  label="Lokalita *"
                  :rules="ruleLokalita">
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                        <div style="line-height:14px;font-size:14px;">{{item.label}}</div>
                        <div style="font-size:12px;color:gray;">{{item.label_okresu}}, kraj {{item.label_kraje}}</div>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <!-- MZDA OD -->
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  label="Mzda od *"
                  clearable
                  :rules="ruleMzdaOd"
                  v-model="formStep.mzda_od"
                  single-line
                  type="number"
                  hint="Mzda od"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <!-- MZDA DO -->
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  label="Mzda do *"
                  clearable
                  :rules="ruleMzdaDo"
                  v-model="formStep.mzda_do"
                  single-line
                  type="number"
                  hint="Mzda do"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <!-- MZDA TYP -->
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  :items="optTypMzda"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.typ_mzda"
                  :rules="ruleTypMzda"
                  label="Typ mzdy *"
                ></v-select>
              </v-col>

              <!-- OBORY -->
              <v-col
                cols="12"
              >
                <v-select
                  :items="optObory"
                  item-text="label"
                  item-value="id"
                  clearable
                  :rules="ruleObory"
                  :counter="maxObor"
                  v-model="formStep.obor"
                  label="Obory *"
                  multiple
                ></v-select>
              </v-col>

              <!-- OBJEDNAVKA CISLO -->
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  label="Objednávka *"
                  clearable
                  :rules="ruleObjednavka"
                  v-model="formStep.cislo_objednavky"
                  single-line
                  type="number"
                  hint="Číslo objednávky"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <!-- POBOCKA -->
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  :items="optPobocka"
                  item-text="label"
                  item-value="id"
                  clearable
                  v-model="formStep.pobocka"
                  label="Pobočka"
                ></v-select>
              </v-col>

              <!-- KONZULTANT -->
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  :items="optKonzultant"
                  item-text="name"
                  item-value="id"
                  clearable
                  v-model="formStep.konzultant"
                  label="Konzultant">
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                        <div style="line-height:14px;font-size:14px;">{{item.firstname}} {{item.surname}}</div>
                        <div style="font-size:12px;color:gray;">{{item.email}}<span v-if="item.mobil">, mobil {{item.mobil}}</span></div>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <!-- IC KLIENTA -->
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="IČ klienta *"
                  clearable
                  :rules="ruleICKlienta"
                  v-model="formStep.ic_klienta"
                  single-line
                  type="number"
                  required
                  @blur="fetchICO"
                ></v-text-field>
              </v-col>

              <!-- JMENO KLIENTA -->
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Jméno klienta"
                  v-model="formStep.jmeno_klienta"
                  :hint="hint_jmeno_klienta"
                  persistent-hint
                  disabled
                ></v-text-field>
              </v-col>

              <!-- DATUM OD -->
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="datum_vystaveni_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDatumVystaveni"
                      label="Inzerovat od *"
                      clearable
                      :prepend-icon="mdiCalendar"
                      readonly
                      :rules="ruleDatumVystaveni"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formStep.datum_vystaveni"
                    locale="cs-CS"
                    @input="datum_vystaveni_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- DATUM DO -->
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="datum_stazeni_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDatumStazeni"
                      label="Inzerovat do"
                      :prepend-icon="mdiCalendar"
                      readonly
                      disabled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formStep.datum_stazeni"
                    locale="cs-CS"
                    @input="datum_stazeni_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
      <div style="text-align:right;">
        <small style="color:red">položky označené * je potřeba vyplnit</small>
      </div>
    </v-card-text>
    <!-- TLACITKA -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="#FF6160"
        text
        @click="$emit('close')"
      >
        ZRUŠIT
      </v-btn>
      <v-btn
        color="success"
        text
        @click="handleNextStep"
      >
        POKRAČOVAT
      </v-btn>
    </v-card-actions>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </v-card>
</template>

<script>
/* eslint no-plusplus: "off" */

import axios from 'axios'
import { mdiCalendar } from '@mdi/js'
import SnackBar from '@/components/snackbar/index.vue'
import response from '@/mixins/response'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  props: ['typ_inzeratu', 'last_step', 'is_new', 'jobForm'],
  mixins: [response],
  data() {
    return {
      mdiCalendar,
      optObory: [],
      optKraj: [],
      optLokalita: [],
      optTypUvazku: [],
      optMinimalniVzdelani: [],
      optZapati: [],
      optPobocka: [],
      optKonzultant: [],
      optTypMzda: [],
      maxObor: 3,
      formStep: {},
      hint_jmeno_klienta: '',
      snack_text: '',
      snack_color: 'red',
      snackbar: false,
      datum_vystaveni_menu: false,
      datum_stazeni_menu: false,
      ruleNazovPozicie: [
        v => !!v || 'Názov pozicie je nutné vyplnit!',
        v => (v && v.length >= 10) || 'Názov pozicie musí obsahovať aspoň 10 znakov.',
      ],
      ruleNaplnPrace: [
        v => !!v || 'Nápln práce je nutné vyplnit!',
        v => (v && v.length >= 10) || 'Nápln práce musí obsahovať aspoň 10 znakov.',
      ],
      ruleNabizime: [
        v => !!v || 'Nabízime je nutné vyplnit!',
        v => (v && v.length >= 10) || 'Nabízime musí obsahovať aspoň 10 znakov.',
      ],
      rulePozadujeme: [
        v => !!v || 'Požadujeme je nutné vyplnit!',
        v => (v && v.length >= 10) || 'Požadujeme musí obsahovať aspoň 10 znakov.',
      ],
      ruleObjednavka: [
        v => !!v || 'Číslo objednávky je nutné vyplnit!',
      ],
      ruleKraj: [
        v => (v || '').length > 0 || 'Kraj je nutné vyplnit!',
      ],
      ruleLokalita: [
        v => (v || '').length > 0 || 'Lokalitu je nutné vyplnit!',
      ],
      ruleMzdaOd: [
        v => (v || '').length > 0 || 'Minimální mzdu je nutné vyplnit!',
      ],
      ruleMzdaDo: [
        v => (v || '').length > 0 || 'Maximální mzdu je nutné vyplnit!',
      ],
      ruleTypMzda: [
        v => (v || '').length > 0 || 'Typ mzdy je nutné vyplnit!',
      ],
      ruleObory: [
        v => (v || '').length > 0 || 'Obory je nutné vyplnit!',
        v => (v || '').length <= this.maxObor || `Maximum oborov je nastaveno na ${this.maxObor} položky!`,
      ],
      ruleICKlienta: [
        v => (v || '').length > 0 || 'IČO klienta je nutné vyplnit!',
      ],
      ruleTypUvazku: [
        v => (v || '').length > 0 || 'Typ úvazku je nutné vyplnit!',
      ],
      ruleMinimalniVzdelani: [
        v => (v || '').length > 0 || 'Minimální vydelání je nutné vyplnit!',
      ],
      ruleDatumVystaveni: [
        v => (v || '').length > 0 || 'Datum vystavení je nutné vyplnit!',
        v => (this.$moment(v, 'DD.MM.YYYY').isSameOrAfter(new Date())) || 'Datum vystavení musí být minimálne dnešné datum!',
      ],
    }
  },
  computed: {
    computedDatumVystaveni() {
      const date_str = `${this.formStep.datum_vystaveni} 00:00:00.000`
      const date = this.$moment(date_str)

      return this.formStep.datum_vystaveni ? this.$moment(new Date(date)).format('DD.MM.YYYY') : ''
    },
    computedDatumStazeni() {
      const date_str = `${this.formStep.datum_stazeni} 00:00:00.000`
      const date = this.$moment(date_str)

      return this.formStep.datum_stazeni ? this.$moment(new Date(date)).format('DD.MM.YYYY') : ''
    },
  },
  watch: {
    'formStep.datum_vystaveni': {
      handler() {
        // PREDNASTAV DATUM STAZENI PODLA TPP ALEBO BRIGÁDA NA 30 ALEBO 15 DNÍ

        if (this.typ_inzeratu === 'TPP') {
          this.formStep.datum_stazeni = this.$moment(new Date(this.formStep.datum_vystaveni)).add(30, 'days').format('YYYY-MM-DD')
        } else { // BRIGADA
          this.formStep.datum_stazeni = this.$moment(new Date(this.formStep.datum_vystaveni)).add(15, 'days').format('YYYY-MM-DD')
        }
      },
    },
  },
  beforeDestroy() {
    console.log('[form-step1.vue] *** beforeDestroy')
  },
  created() {
    console.log('[form-step1.vue] *** created')

    this.formStep = Object.assign(this.jobForm)

    if (this.is_new === true && this.formStep.datum_vystaveni === '1980-02-07') {
      const date = new Date(this.$moment(new Date()).add(1, 'day'))
      this.formStep.datum_vystaveni = this.$moment(date).format('YYYY-MM-DD')
    }

    // ---

    this.fetchCiselnik('kraj', 'optKraj')
    this.fetchCiselnik('lokalita')
    this.fetchCiselnik('minimalni_vzdelani', 'optMinimalniVzdelani')
    this.fetchCiselnik('pobocka', 'optPobocka')
    this.fetchCiselnik('konzultant', 'optKonzultant')
    if (this.typ_inzeratu === 'TPP') {
      this.fetchCiselnik('obory', 'optObory')
      this.fetchCiselnik('zapati', 'optZapati')
      this.fetchCiselnik('typ_uvazku', 'optTypUvazku')
      this.fetchCiselnik('typ_mzda', 'optTypMzda')
    } else { // BRIGÁDA
      this.fetchCiselnik('obory_brigada', 'optObory')
      this.fetchCiselnik('typ_mzda_brigada', 'optTypMzda')
    }
  },
  methods: {
    handleNextStep() {
      if (!this.$refs.formStep.validate()) { // zatial vypinam validate
        this.$emit('nextStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep1').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
    fetchCiselnik(p_ciselnik, p_var) {
      const body = JSON.stringify({})
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/inzerce/data/list/${p_ciselnik}`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          if (p_ciselnik === 'lokalita') {
            let kraj = ''
            for (let index = 0; index < resp.data.db_data.length; index++) {
              const element = resp.data.db_data[index]
              if (kraj !== element.label_kraje) {
                kraj = element.label_kraje
                this.optLokalita.push({ header: kraj })
                this.optLokalita.push({ divider: true })
              }
              this.optLokalita.push(element)
            }
          } else {
            this[p_var] = resp.data.db_data
          }
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    fetchICO() {
      const ico = this.formStep.ic_klienta || undefined
      if (!ico) {
        return
      }

      // ---

      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/tool/ico/${ico}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('ICO => ', resp.data.db_data[0]['Název subjektu:'])

          this.formStep.jmeno_klienta = resp.data.db_data[0]['Název subjektu:']
          this.hint_jmeno_klienta = resp.data.db_data[0]['Sídlo:']
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    handleNazovPozicieDalsie() {
      this.formStep.nazov_pozicie_dalsie = this.formStep.nazov_pozicie_dalsie || []
      this.formStep.nazov_pozicie_dalsie[0] = this.formStep.nazov_pozicie
    },
  },
}
</script>
