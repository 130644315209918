<template>
  <v-card>
    <v-card-text>
      <v-container>
        <div id="divStep2">
          <v-form ref="formStep">
            <v-row v-for="(nazov, index) in formStep.nazov_pozicie_dalsie" :key="nazov + index">
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-text-field
                  :value="nazov"
                  :rules="ruleNazovPozicie"
                  @blur="handleNazovPozicie(...arguments, index)"
                  label="Název pozice *"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                md="2"
              >
                <div
                  v-show="index===formStep.nazov_pozicie_dalsie.length-1"
                  style="line-height:70px"
                >
                  <!-- PRIDAT NAZOV -->
                  <v-btn
                    v-if="index < maxNazvov-1"
                    @click="formStep.nazov_pozicie_dalsie.push('')"
                    style="margin-right: 10px"
                    outlined
                    color="primary"
                    tile
                    small
                  >
                    PŘIDAT
                  </v-btn>
                  <!-- ODSTRANT NAZOV -->
                  <v-btn
                    v-if="index > 0"
                    @click="formStep.nazov_pozicie_dalsie.splice(index, 1)"
                    outlined
                    color="#FF6160"
                    icon
                    tile
                    small
                  >
                    <v-icon>{{mdiDeleteOutline}}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
      <div style="text-align:right;">
        <small style="color:red">položky označené * je potřeba vyplnit</small>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="#FF6160"
        text
        @click="$emit('close')"
      >
        ZRUŠIT
      </v-btn>
      <v-btn
        color="#6D8793"
        text
        @click="handlePreviousStep"
      >
        ZPĚT
      </v-btn>
      <v-btn
        color="success"
        text
        @click="handleNextStep"
      >
        POKRAČOVAT
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint prefer-destructuring: ["off", {AssignmentExpression: {array: true}}] */
/* eslint no-plusplus: "off" */
import { mdiDeleteOutline } from '@mdi/js'

export default {
  props: ['typ_inzeratu', 'last_step', 'jobForm'],
  data() {
    return {
      mdiDeleteOutline,
      maxNazvov: 5,
      formStep: {},
      ruleNazovPozicie: [
        v => !!v || 'Názov pozicie je nutné vyplnit!',
        v => (v && v.length >= 10) || 'Názov pozicie musí obsahovať aspoň 10 znakov.',
      ],
    }
  },
  watch: {

  },
  beforeDestroy() {
    console.log('[form-step2.vue] *** beforeDestroy')
  },
  created() {
    console.log('[form-step2.vue] *** created')

    this.formStep = Object.assign(this.jobForm)
  },
  methods: {
    handleNextStep() {
      if (this.$refs.formStep.validate()) {
        this.$emit('nextStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep2').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
    handlePreviousStep() {
      if (this.$refs.formStep.validate()) {
        this.$emit('previousStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep2').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
    handleNazovPozicie(e, p_index) {
      this.formStep.nazov_pozicie_dalsie[p_index] = e.target.value
      if (p_index === 0) {
        this.formStep.nazov_pozicie = this.formStep.nazov_pozicie_dalsie[0]
      }
    },
  },
}
</script>
