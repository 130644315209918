<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1400px"
    >

      <template>
        <v-stepper
          v-if="typ_inzeratu !== undefined"
          v-model="step_current"
          vertical
        >
          <div v-for="step, index in enabled_steps" :key="index">
            <v-stepper-step :step="index+1">
              {{ step.title }}
              <small>{{ step.smalltitle }}</small>
            </v-stepper-step>

            <!-- KROK 1. -->
            <v-stepper-content
              v-if="step.id === 1"
              step="1">
              <form-step1
                v-if="step_current===1"
                :typ_inzeratu="typ_inzeratu"
                :is_new="dataForm.is_new"
                :jobForm="jobForm"
                @close="$emit('close')"
                @nextStep="nextStep">
              </form-step1>
            </v-stepper-content>

            <!-- KROK 2. -->
            <v-stepper-content
            v-if="step.id === 2"
            step="2">
            <form-step2
              v-if="step_current===2"
              :typ_inzeratu="typ_inzeratu"
              :jobForm="jobForm"
              @close="$emit('close')"
              @nextStep="nextStep"
              @previousStep="previousStep">
            </form-step2>
            </v-stepper-content>

            <!-- KROK 3. -->
            <v-stepper-content
              v-if="step.id === 3"
              step="3">
              <form-step3
                v-if="step_current===3"
                :last_step="last_step[typ_inzeratu] === step.name ? true : false"
                :typ_inzeratu="typ_inzeratu"
                :jobForm="jobForm"
                @close="$emit('close')"
                @nextStep="nextStep"
                @previousStep="previousStep">
              </form-step3>
            </v-stepper-content>

            <!-- KROK 4. -->
            <v-stepper-content
              v-if="step.id === 4"
              step="4">
              <form-step4
                v-if="step_current===4"
                :last_step="last_step[typ_inzeratu] === step.name ? true : false"
                :typ_inzeratu="typ_inzeratu"
                :jobForm="jobForm"
                @close="$emit('close')"
                @nextStep="nextStep"
                @previousStep="previousStep">
              </form-step4>
            </v-stepper-content>

            <!-- KROK 5. -->
            <v-stepper-content
              v-if="step.id === 5"
              step="5">
              <form-step5
                v-if="step_current===5"
                :last_step="last_step[typ_inzeratu] === step.name ? true : false"
                :typ_inzeratu="typ_inzeratu"
                :jobForm="jobForm"
                @close="$emit('close')"
                @nextStep="nextStep"
                @previousStep="previousStep">
              </form-step5>
            </v-stepper-content>
          </div>

        </v-stepper>
      </template>
    </v-dialog>

    <!-- VYBER MEDZI TPP a BRIGÁDA -->
    <v-dialog
      v-model="show_typ_inzeratu"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <div style="text-align:center;width:100%">Zvolte typ inzerátu</div>
        </v-card-title>

        <v-card-text>
          <div style="text-align:center">Chci vytvořit TPP nebo BRIGÁDU.</div>
          <v-row>
            <v-col :cols="6">
              <div
                style="text-align:center;line-height:64px"
              >
                <v-btn
                  large
                  color="primary"
                  style="width:120px"
                  @click="setTypInzeratu('TPP')"
                >
                  TPP
                </v-btn>
              </div>
            </v-col>
            <v-col :cols="6">
              <div
                style="text-align:center;line-height:64px"
              >
                <v-btn
                  large
                  color="primary"
                  style="width:120px"
                  @click="setTypInzeratu('BRIGÁDA')"
                >
                  BRIGÁDA
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint no-plusplus: "off" */
import FormStep1 from './form-step1.vue'
import FormStep2 from './form-step2.vue'
import FormStep3 from './form-step3.vue'
import FormStep4 from './form-step4.vue'
import FormStep5 from './form-step5.vue'

export default {
  components: {
    'form-step1': FormStep1,
    'form-step2': FormStep2,
    'form-step3': FormStep3,
    'form-step4': FormStep4,
    'form-step5': FormStep5,
  },
  props: ['dialog', 'dataForm'],
  data() {
    return {
      show_typ_inzeratu: false,
      typ_inzeratu: undefined,
      step_current: 1,
      last_step: {
        TPP: 'teamio',
        BRIGÁDA: 'fajnsprava',
      },
      steps: [
        {
          id: 0,
          name: 'empty',
          disabled: true,
        },
        {
          id: 1,
          title: 'Společné informace k pozici',
          smalltitle: 'Vyplňte první krok',
          disabled: false,
          name: 'basic',
          typ_inzeratu: 'both',
        },
        {
          id: 2,
          title: 'Další názvy pozice',
          smalltitle: 'Názvy pro různe pracovní portály',
          disabled: false,
          name: 'names',
          typ_inzeratu: 'both',
        },
        {
          id: 3,
          title: 'jenprace.cz',
          smalltitle: 'Inzerce na portálu',
          disabled: false,
          name: 'jenprace',
          typ_inzeratu: 'both',
        },
        {
          id: 4,
          title: 'teamio.com',
          smalltitle: 'Inzerce na portálu jobs.cz a prace.cz',
          disabled: false,
          name: 'teamio',
          typ_inzeratu: 'both',
        },
        {
          id: 5,
          title: 'fajn-sprava.cz',
          smalltitle: 'Inzerce na portálu fajn-brigady.cz',
          disabled: false,
          name: 'fajnsprava',
          typ_inzeratu: 'BRIGÁDA',
        },
      ],
      enabled_steps: [],
      jobForm: { // NIKDY NEMEN TENTO OBJECT V JEDNOTLIVYCH KROKOCH!!! => toto je hlavny object pre create/update inzeratu, sem sa zapisuju jednotlive kroky po kliknuti na tlacitko
        nazov_pozicie_dalsie: [],
        datum_vystaveni: this.$moment('1980-02-07').format('YYYY-MM-DD'),
        datum_stazeni: this.$moment('1980-02-07').format('YYYY-MM-DD'),
        tmp_dates_zvyhodneni: [], // formStep.jenpracecz_kalendar_zvyhodneni
      },
    }
  },
  computed: {

  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog === true) { // ked sa *otvori* dialog pre create/update inzeratu
          this.step_current = 1
          this.show_typ_inzeratu = true
          this.jobForm = {
            nazov_pozicie_dalsie: [],
            datum_vystaveni: this.$moment('1980-02-07').format('YYYY-MM-DD'),
            datum_stazeni: this.$moment('1980-02-07').format('YYYY-MM-DD'),
          }
        } else { // ked sa *zatvori* dialog pre create/update inzeratu
          this.typ_inzeratu = undefined
          this.show_typ_inzeratu = false
          this.step_current = 1
          this.enabled_steps = []
        }
      },
    },
    typ_inzeratu: {
      handler() {
        this.enabledSteps()
      },
    },
  },
  beforeDestroy() {

  },
  created() {
  },
  methods: {
    enabledSteps() {
      this.enabled_steps = []
      for (let index = 0; index < this.steps.length; index++) {
        const element = this.steps[index]
        if (element.disabled === false && (this.typ_inzeratu === element.typ_inzeratu || element.typ_inzeratu === 'both')) {
          this.enabled_steps.push(element)
        }
      }
    },
    nextStep(p_form_data) {
      console.log('[form-inzerat.vue] step_current => ', this.step_current)

      if (p_form_data.validate && p_form_data.validate === true) {
        if (this.last_step[this.typ_inzeratu] === this.steps[this.step_current].name) {
          this.saveJob()

          return
        }

        this.updateJobFrom(p_form_data)

        for (let index = this.step_current; index < this.steps.length; index++) {
          const element = this.steps[index]
          if ((element.disabled === false) && (this.step_current !== element.id) && ((element.typ_inzeratu === 'both') || (element.typ_inzeratu === this.typ_inzeratu))) {
            this.step_current = element.id
            break
          }
        }

        console.log('nextStep, jobForm =>', this.jobForm)
      }
    },
    previousStep(p_form_data) {
      if (this.step_current === 1) {
        console.log('previousStep, this.step_current =>', this.step_current)

        return
      }

      this.updateJobFrom(p_form_data)

      for (let index = this.step_current - 1; index >= 0; index--) {
        const element = this.steps[index]
        if ((element.disabled === false) && (this.step_current !== element.id) && ((element.typ_inzeratu === 'both') || (element.typ_inzeratu === this.typ_inzeratu))) {
          this.step_current = element.id
          break
        }
      }
    },
    updateJobFrom(p_form_data) {
      // ---
      // ZAPIS DATA Z PRIJATEHO KROKU DO GLOBALNEHO OBJECTU

      this.jobForm = Object.assign(this.jobForm, p_form_data.dataForm)
      console.log('updateJobFrom, jobForm =>', this.jobForm)

      // ---
    },
    setTypInzeratu(p_type) {
      this.show_typ_inzeratu = false
      this.typ_inzeratu = p_type
    },
    detailJob() {
      console.log('*** DETAIL JOB!!! ***')

      this.jobForm.tmp_dates_zvyhodneni = []
      if (this.jobForm.jenpracecz_kalendar_zvyhodneni) {
        if (this.jobForm.jenpracecz_kalendar_zvyhodneni.length > 0) {
          this.jobForm.tmp_dates_zvyhodneni = this.jobForm.jenpracecz_kalendar_zvyhodneni.split(',')
        }
      }
    },
    saveJob() {
      console.log('*** SAVE JOB!!! ***')

      delete this.formStep.tmp_dates_zvyhodneni // toto nezapisovat do DB
    },
  },
}
</script>
