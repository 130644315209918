<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              label="Název pozice pro fajnsprava"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="#FF6160"
        text
        @click="$emit('close')"
      >
        ZRUŠIT
      </v-btn>
      <v-btn
        color="#6D8793"
        text
        @click="handlePreviousStep"
      >
        ZPĚT
      </v-btn>
      <v-btn
        color="success"
        text
        @click="handleNextStep"
      >
        {{ last_step === true ? 'ULOŽIT' : 'POKRAČOVAT' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['typ_inzeratu', 'last_step', 'jobForm'],
  data() {
    return {
      dataForm: {},
    }
  },
  created() {
    console.log('[form-step5.vue] *** created')
  },
  methods: {
    handleNextStep() {
      this.$emit('nextStep', { validate: true, dataForm: this.dataForm })
    },
    handlePreviousStep() {
      this.$emit('previousStep')
    },
  },
}
</script>
