<template>
  <v-card>
    <v-card-text>
      <v-container>
        <div id="divStep3">
          <v-form ref="formStep">
            <v-row>
              <!-- VYSTAVIT NA PORTAL -->
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <div
                style="float:right">
                  <v-switch
                    v-model="formStep.jenprace_food_active"
                    :label="`Chci vystavit na portál: ${formStep.jenprace_food_active ? 'Áno' : 'Ne'}`"
                  ></v-switch>
                </div>
              </v-col>
              <!-- NAZOV POZICIE -->
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  :items="formStep.nazov_pozicie_dalsie"
                  clearable
                  v-model="formStep.nazev_pozice_jenprace"
                  label="Název pozice pro jenprace.cz *"
                  :rules="ruleNazevPoziceJenprace"
                  :disabled="!formStep.jenprace_food_active"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-subheader
                  :style="!formStep.jenprace_food_active ? 'color:#D5D4D8;' : ''"
                >
                  Placené služby(nepovinné)
                </v-subheader>
                <v-divider inset></v-divider>
              </v-col>
              <!-- ZVYHODNENI -->
              <v-col
                cols="12"
                sm="12"
                md="4"
                style="text-align:center;"
              >
                <v-date-picker
                  v-model="formStep.tmp_dates_zvyhodneni"
                  locale="cs-CS"
                  multiple
                  :disabled="!formStep.jenprace_food_active"
                  :min="$moment(formStep.datum_vystaveni).format('YYYY-MM-DD')"
                  :max="$moment(formStep.datum_stazeni).format('YYYY-MM-DD')"
                  :selected-items-text="formStep.tmp_dates_zvyhodneni ? `${formStep.tmp_dates_zvyhodneni.length} vybráno` : ''"
                  :allowed-dates="datesAllowedZvyhodneni"
                  @click="handleDatesZvyhodneni()"
                >
                </v-date-picker>
                <v-text-field
                  v-model="formStep.tmp_dates_zvyhodneni"
                  readonly
                  label="Zvýhodnění"
                  locale="cs-CS"
                  :rules="ruleDatesZvyhodneni"
                ></v-text-field>
              </v-col>
              <!-- FACEBOOK -->
              <v-col
                cols="12"
                sm="12"
                md="4"
                style="text-align:center;"
              >
                <div
                  class="subheading"
                  :style="!formStep.jenprace_food_active ? 'color:#D5D4D8;' : ''"
                >
                  Facebook
                </div>
                <v-date-picker
                  v-model="formStep.jenpracecz_kalendar_facebook"
                  locale="cs-CS"
                  :disabled="!formStep.jenprace_food_active"
                  :min="$moment(formStep.datum_vystaveni).format('YYYY-MM-DD')"
                  :max="$moment(formStep.datum_stazeni).format('YYYY-MM-DD')"
                >
                </v-date-picker>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
      <div style="text-align:right;">
        <small style="color:red">položky označené * je potřeba vyplnit</small>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="#FF6160"
        text
        @click="$emit('close')"
      >
        ZRUŠIT
      </v-btn>
      <v-btn
        color="#6D8793"
        text
        @click="handlePreviousStep"
      >
        ZPĚT
      </v-btn>
      <v-btn
        color="success"
        text
        @click="handleNextStep"
      >
        {{ last_step === true ? 'ULOŽIT' : 'POKRAČOVAT' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint no-plusplus: "off" */
/* eslint consistent-return: "off" */
/* eslint no-else-return: "off" */

export default {
  props: ['typ_inzeratu', 'last_step', 'jobForm'],
  data() {
    return {
      formStep: {
        tmp_dates_zvyhodneni: [],
      },
      dates_allowed_zvyhodneni: [],
      ruleNazevPoziceJenprace: [
        v => (v || '').length > 0 || 'Název pozice je nutné vyplnit!',
      ],
      ruleDatesZvyhodneni: [
        v => (v || '').length < 4 || 'Můžete označit max tři data!',
      ],
    }
  },
  watch: {
    'formStep.tmp_dates_zvyhodneni': {
      deep: true,
      immediate: true,
      handler() {
        this.handleDatesZvyhodneni()
      },
    },
  },
  created() {
    console.log('[form-step3.vue] *** created')

    this.formStep = { ...this.jobForm }
    console.log('on Created formStep =>', this.formStep)

    if (!this.formStep.jenpracecz_kalendar_zvyhodneni) {
      this.formStep.jenpracecz_kalendar_zvyhodneni = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }
    if (!this.formStep.jenpracecz_kalendar_facebook) {
      this.formStep.jenpracecz_kalendar_facebook = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }
  },
  methods: {
    handleNextStep() {
      if (this.formStep.jenprace_food_active !== true) { // v pripade, ze sa na portal nevystavuje, nevaliduj pri kliknuti na pokracovat
        this.$emit('nextStep', { validate: true, dataForm: this.formStep })
      }

      // ---

      if (this.$refs.formStep.validate()) { // zatial vypinam validate
        this.$emit('nextStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep3').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
    handlePreviousStep() {
      if (this.$refs.formStep.validate()) {
        this.$emit('previousStep', { validate: true, dataForm: this.formStep })
      } else {
        this.snack_text = 'Opravte červeně označené položky, jinak nebudete moci pokračovat!'
        this.snack_color = '#F66060'
        this.snackbar = true
        document.getElementById('divStep3').scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    },
    handleDatesZvyhodneni() {
      console.log('### handleDatesZvyhodneni ###')
      this.formStep.jenpracecz_kalendar_zvyhodneni = this.formStep.tmp_dates_zvyhodneni ? this.formStep.tmp_dates_zvyhodneni.toString() : ''

      this.dates_allowed_zvyhodneni = []
      const vystaveni = this.$moment(this.formStep.datum_vystaveni)
      const stazeni = this.$moment(this.formStep.datum_stazeni)
      const range_days = stazeni.diff(vystaveni, 'days')
      console.log('range_days =>', range_days)
      console.log('this.formStep.tmp_dates_zvyhodneni =>', this.formStep.tmp_dates_zvyhodneni)
      if (this.formStep.tmp_dates_zvyhodneni) {
        for (let index = 0; index < this.formStep.tmp_dates_zvyhodneni.length; index++) {
          const date_selected = this.formStep.tmp_dates_zvyhodneni[index]
          for (let day = 0; day < range_days; day++) {
            const date = this.$moment(vystaveni).add(day, 'days')
            const sub7 = this.$moment(date_selected).subtract(7, 'days')
            const add7 = this.$moment(date_selected).add(7, 'days')
            console.log('isBefore =>', this.$moment(date).format('DD.MM.YYYY'), this.$moment(sub7).format('DD.MM.YYYY'))
            console.log('isAfter =>', this.$moment(date).format('DD.MM.YYYY'), this.$moment(add7).format('DD.MM.YYYY'))
            if (this.$moment(date).isBefore(sub7) || (this.$moment(date).isAfter(add7))) {
              this.dates_allowed_zvyhodneni.push(date)
            }
          }
        }
      }

      console.log('dates_allowed_zvyhodneni.push =>', this.dates_allowed_zvyhodneni)
    },
    datesAllowedZvyhodneni(val) {
      for (let index = 0; index < this.dates_allowed_zvyhodneni.length; index++) {
        const date = this.dates_allowed_zvyhodneni[index]
        console.log('datesAllowedZvyhodneni, val =>', this.$moment(val).isSame(this.$moment(date)))
        if (this.$moment(val).isSame(this.$moment(date))) {
          return true
        } else {
          return false
        }
      }
    },
  },
}
</script>
